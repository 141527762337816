<template>
  <div class="auth_app">
    <div class="btn_panel">
      <div class="btn_title">
        <span>选择会员</span>
      </div>
        <div class="btn persion" @click="goPage(3)">
      </div>
        <div class="btn company" @click="goPage(2)">
      </div>
    </div>
    <div class="auth_exit">
        <a href="javascript:void(0)" @click="exit">退出</a>
    </div>
  </div>
</template>

<script>
import {userLogout} from '../../api/login'
import {logout} from '../../store/token'
import {allinpayUser} from '../../store/allinpay'
// import {providerInfo} from '../../store/provider'
export default {
  data(){
    return {
      user:''
    }
  },
  created(){
    this.user = allinpayUser.get();
  },
  methods:{
    exit(){
      userLogout().then(()=>{
      }).finally(()=>{
        logout();
      });
    },
    goPage(type){
      allinpayUser.update({memberType:type})
      if(type==3){
        if(this.user.userSource == 1){
          this.$router.replace({path:'/bank/bindPhone'});
        }else{
          this.$router.replace({path:'/bank/bindPhone',query:{scrapUserId:this.user.userId}})
        }
      }else{
        if(this.user.userSource == 1){
          if(this.user.newUser){
            this.$router.push({path:'/bank/companyAuth',query:{first:true}});
          }
        }else{
          this.$router.replace({path:'/bank/companyAuth'})
        }
      }
    },
  }
}
</script>

<style scoped>
.auth_exit{
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
}
.auth_exit a:link{
  color: white  ;
} 
.auth_app{
  background-image: url('../../assets/image/auth_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  height: 100vh;
  width: 100vw;
}
.btn_panel{
  width: 100%;
  text-align: center;
  /* margin-top: 40vh; */
  position: absolute;
  top: 36vh;
}
.btn_title{
  color: #F3E6C5;
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 40px;
}
.btn{
  width: 80%;
  display: inline-block;
  height: 63px;
  line-height: 63px;
  text-align: center;
  color: #d8ac7e;
  font-family: '黑体';
  /* font-weight: bold; */
  font-size: 24px;
  background-size: 100% 100%;
  cursor: pointer;
}
.persion{
  background-image: url('../../assets/image/persion_btn.png');
}
.company{
  margin-top: 30px;
  background-image: url('../../assets/image/company_btn.png');
}
</style>